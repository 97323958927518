<template>
  <CContainer class="approve-request-container">
    <LoginFormComponent
      :formType="isExpired==true? 'EXPIRED_APPROVE_REQUEST_LINK' : 'APPROVE_REQUEST'"
      :formTitle="isExpired==true ? 'Link expired' : ''"
    />
  </CContainer>
</template>

<script>
import _ from 'lodash';
import LoginFormComponent from '@/components/LoginFormComponent';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import loginHelper from '@/utils/login-helper';
import { env, RoleType } from '@/constants';

export default {
  name: 'ApproveRequest',
  data() {
    return {
      themeName: env.theme.name,
      token: '',
      isExpired: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    next(vm => {
      const role = vm.$store.state.auth ? vm.$store.state.auth.role : null;
      const isMaster = role ? role.root_flg : false;

      if (!isMaster) {
        return next(loginHelper.getLoginUrl());
      }

      next();
    });
  },
  async mounted() {
    const token = _.trim(this.$route.query.token);
    this.token = token;

    let resultTokens;
    let errorCode;
    try {
      resultTokens = await this.$http.get(endpoints.checkRequestToken(this.token));
    } catch (err) {
      errorCode = err.code;
    }

    if (errorCode === 'TOKEN_INVALID' || resultTokens.data.token_sts !== 'VALID') {
      this.isExpired = true;
    } else {
      this.approveChangeRequest();
    }
  },
  components: { LoginFormComponent },
  methods: {
    async approveChangeRequest() {
      try {
        const params = {
          status: 3,
          token: this.token,
        };
        const result = await this.$http.post(endpoints.approveChangeCommissionAddressRequest, params);

        if (result && result.data) {
          this.$notify({
            group: 'notifications',
            type: 'success',
            title: 'Success',
            text: 'Your link has been confirmed!',
          });

          this.toAdminPage();
        }
      } catch (err) {
        if (err.code === 'TOKEN_EXPIRED') {
          this.isExpired = true;
          return;
        }

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Active',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    toAdminPage() {
      this.$router.push('/admin');
    },
  },
};
</script>

<style lang="scss">
.approve-request-container {
}
</style>
